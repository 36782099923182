import { Icon, IconTypes, Text } from 'fitify-ui'
import { ThemeVariants } from 'fitify-ui/src/theme'
import { LinkTypes } from 'fitify-ui-landing/src/@types'
import Container from 'fitify-ui-landing/src/components/Container/Container'
import NavigationMenu, {
  NavigationVariant,
} from 'fitify-ui-landing/src/components/Navigation/NavigationMenu'
import Link from 'next/link'
import { useTranslation } from 'next-i18next'
import { useTheme } from 'styled-components'

import {
  StyledFooter,
  StyledFooterBrandImage,
  StyledFooterCopy,
  StyledFooterGrid,
  StyledFooterImagesColumn,
  StyledFooterMenuColumn,
  StyledFooterStoreIcons,
} from './Footer.Styled'

interface FooterProps {
  copyLinks: LinkTypes.Navigation[]
  socialLinks: LinkTypes.Navigation[]
  navigationLinks: LinkTypes.Navigation[]
  storeLinks?: {
    name: string
    icon: IconTypes.Variants
    link: string
  }[]
  logo: React.ReactNode
  companyName?: string
}

const Footer = ({
  copyLinks,
  socialLinks,
  navigationLinks,
  storeLinks,
  logo,
  companyName,
}: FooterProps) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const isDigital = theme.variant === ThemeVariants.DIGITAL
  return (
    <StyledFooter>
      <Container>
        <StyledFooterGrid>
          <StyledFooterImagesColumn>
            <StyledFooterBrandImage
              href="/"
              aria-label={
                isDigital
                  ? t('aria_label_go_to_homepage')
                  : t('hc_aria_label_go_to_homepage')
              }
            >
              {logo}
            </StyledFooterBrandImage>
            {storeLinks && storeLinks.length > 0 ? (
              <StyledFooterStoreIcons>
                {storeLinks &&
                  storeLinks.map((storeLink, index: number) => (
                    <Link
                      key={index}
                      href={storeLink.link}
                      target={'_blank'}
                      rel="noreferrer"
                      aria-label={storeLink.name}
                    >
                      <Icon name={storeLink.icon} />
                    </Link>
                  ))}
              </StyledFooterStoreIcons>
            ) : null}
          </StyledFooterImagesColumn>

          <StyledFooterMenuColumn>
            <NavigationMenu
              items={navigationLinks}
              variant={NavigationVariant.FOOTER}
            />

            <NavigationMenu
              items={socialLinks}
              variant={NavigationVariant.FOOTER}
            />
          </StyledFooterMenuColumn>
        </StyledFooterGrid>

        <StyledFooterCopy>
          <Text
            variant={'footer-copy'}
            html={`&copy ${new Date().getFullYear()} ${companyName}`}
          />

          <NavigationMenu items={copyLinks} variant={NavigationVariant.COPY} />
        </StyledFooterCopy>
      </Container>
    </StyledFooter>
  )
}

export default Footer
