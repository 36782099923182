import { colors } from 'fitify-ui'
import { ThemeVariants } from 'fitify-ui/src/theme'
import { tabletPortrait } from 'fitify-ui-landing/src/theme/breakpoints'
import Link from 'next/link'
import styled, { css } from 'styled-components'

export const StyledFooter = styled.div`
  margin-top: 80px;
  padding-top: 80px;
  padding-bottom: 105px;
  background: ${colors.White};
  border-radius: 40px 40px 0 0;

  ${tabletPortrait(css`
    margin-top: 24px;
    padding-top: 40px;
    border-radius: 24px 24px 0 0;
  `)};

  ${({ theme }) => {
    if (theme.variant === ThemeVariants.DIGITAL) {
      return css`
        background: ${colors.Blue900};
      `
    } else {
      return css`
        background: ${colors.White};
      `
    }
  }}
`

export const StyledFooterGrid = styled.div`
  display: flex;
  justify-content: space-between;

  ${tabletPortrait(css`
    flex-direction: column;
  `)};
`

export const StyledFooterBrandImage = styled(Link)`
  cursor: pointer;
`

export const StyledFooterStoreIcons = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 80px;

  a {
    &:hover {
      opacity: 0.55;
    }
  }
`

export const StyledFooterImagesColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: max-content;

  ${tabletPortrait(css`
    order: 2;

    ${StyledFooterStoreIcons} {
      order: 1;
      margin-top: 0px;
      margin-bottom: 80px;
    }

    ${StyledFooterBrandImage} {
      order: 2;
    }
  `)};
`

export const StyledFooterMenuColumn = styled.div`
  display: flex;
  max-width: 480px;
  width: 100%;
  justify-content: space-between;

  ${tabletPortrait(css`
    flex-direction: column;
    gap: 80px;
    margin-bottom: 80px;
  `)};
`

export const StyledFooterCopy = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 160px;

  ul {
    margin-top: 0;
  }

  ${tabletPortrait(css`
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    margin-top: 64px;
  `)};
`
